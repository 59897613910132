import {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  Alert,
  Box,
  Chip,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import VpnKeyIconOutlined from '@mui/icons-material/VpnKeyOutlined';
import {enqueueSnackbar} from 'notistack';
import {fetchActivatedUsers} from '../../redux/actions/userActions';
import {PARTNER_USER_STATUS_LICENSED} from '../../utils/constants';

function Status() {
  const dispatch = useDispatch();
  const {tenantStatus, error: tenantStatusError} = useSelector(
    (state) => state.tenantStatus
  );
  const {
    licenses,
    isLoading: licenseLoading,
    error: licenseError,
  } = useSelector((state) => state.licenses);
  const {
    users,
    isLoading: userLoading,
    error: userError,
  } = useSelector((state) => state.users);

  useEffect(() => {
    if (tenantStatus?.consentStatus) dispatch(fetchActivatedUsers());
  }, [dispatch, tenantStatus?.consentStatus]);

  useEffect(() => {
    if (licenseError) {
      enqueueSnackbar(licenseError, {variant: 'error'});
    }
    if (userError) {
      enqueueSnackbar(userError, {variant: 'error'});
    }
    if (tenantStatusError) {
      enqueueSnackbar(tenantStatusError, {variant: 'error'});
    }
  }, [userError, licenseError, tenantStatusError, enqueueSnackbar]);

  const isLoading = useMemo(
    () => licenseLoading || userLoading,
    [licenseLoading, userLoading]
  );

  const maskLicense = (license) => license.replace(/[a-zA-Z0-9](?=.{4})/g, '*');

  const numProvisionedUsers = useMemo(() => {
    if (!users || !Array.isArray(users)) {
      return 0;
    }
    const provisionedUsers = users.filter(
      (user) => user.provisionStatus === PARTNER_USER_STATUS_LICENSED
    );
    return provisionedUsers.length;
  }, [users]);

  const tenantStatusActive = tenantStatus?.provisionStatus === 'Enabled';
  const hasActiveLicense = licenses.some((license) => license.active);

  return (
    <Paper sx={{padding: '20px', minHeight: '180px'}}>
      <Typography variant="h4" sx={{pb: 2}}>
        Status
      </Typography>
      {isLoading ? (
        <Box sx={{width: '100%', mt: 4}}>
          <LinearProgress sx={{marginTop: '32px'}} />
        </Box>
      ) : (
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Consent status</TableCell>
              <TableCell>
                {tenantStatus?.consentStatus ? (
                  <Alert
                    variant="outlined"
                    severity="success"
                    sx={{display: 'flex', alignItems: 'center', width: 1 / 3}}
                  >
                    Consent Granted
                  </Alert>
                ) : (
                  <Alert
                    variant="outlined"
                    severity="error"
                    sx={{display: 'flex', alignItems: 'center', width: 1 / 3}}
                  >
                    Need Consent
                  </Alert>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Active Anyware license</TableCell>
              <TableCell>
                {(() => {
                  if (licenses.length > 0 && hasActiveLicense) {
                    return licenses
                      .filter((license) => license.active === true)
                      .map((license) => (
                        <Chip
                          key={license._id}
                          icon={<VpnKeyIconOutlined />}
                          label={maskLicense(license.licenseKey)}
                          color={license.active ? 'primary' : 'default'}
                          variant="outlined"
                          sx={{fontFamily: 'monospace'}}
                        />
                      ));
                  }
                  if (licenses.length > 0 && !hasActiveLicense) {
                    return (
                      <Alert
                        variant="outlined"
                        severity="error"
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          width: 1 / 3,
                        }}
                        data-testid="license-alert"
                      >
                        No License Activated
                      </Alert>
                    );
                  }
                  return (
                    <Alert
                      variant="outlined"
                      severity="error"
                      sx={{display: 'flex', alignItems: 'center', width: 1 / 3}}
                      data-testid="license-alert"
                    >
                      No License Added
                    </Alert>
                  );
                })()}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Integration status</TableCell>
              <TableCell>
                {tenantStatusActive ? (
                  <Alert
                    variant="outlined"
                    severity="success"
                    sx={{display: 'flex', alignItems: 'center', width: 1 / 3}}
                  >
                    Integrations Enabled
                  </Alert>
                ) : (
                  <Alert
                    variant="outlined"
                    severity="error"
                    sx={{display: 'flex', alignItems: 'center', width: 1 / 3}}
                  >
                    Integrations Disabled
                  </Alert>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Total number of provisioned users</TableCell>
              <TableCell>
                <Alert
                  icon={false}
                  variant="outlined"
                  severity="info"
                  sx={{display: 'flex', alignItems: 'center', width: 1 / 3}}
                >
                  {numProvisionedUsers}
                </Alert>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
    </Paper>
  );
}

export default Status;
