/* eslint-disable max-len */

// Urls
export const AWM_EULA_LINK = 'https://cas.teradici.com/EULA.pdf';
export const PRIVACY_STATEMENT_LINK = 'http://www.hp.com/go/privacy';
export const UNAPPROVED_TENANT_REDIRECT_LINK =
  'https://reinvent.hp.com/Anyware-for-windows365';
export const UNAPPROVED_TENANT_REDIRECT_SECONDS = 20;

export const LOGOUT_LINK = '/app/logout';
export const DASHBOARD_LINK = '/app/dashboard';
export const WORKSTATIONS_LINK = '/app/cloudpc';
export const AD_USERS_LINK = '/app/users';
export const AD_GROUPS_LINK = '/app/groups';

// Home page text
export const PAGE_TITLE = 'HP Anyware for Windows 365';
export const HOME_PAGE_INFO_TEXT =
  'Automated provisioning, management of cloud compute costs, and brokering of connections to Windows 365.';
export const FOOTER_TEXT = 
  'HP Anyware for Windows 365 is currently optimized for North America';

export const BACKEND_ERROR_MESSAGE =
  'An error occurred in our backend, please try again. This failure has been logged so we can improve performance.';

// User Roles
export const ROLE_ADMINISTRATOR = 'administrator';
export const ROLE_USER = 'user';
export const ROLE_USER_ADMIN = 'user_admin';

// Viewport breakpoints
export const BREAKPOINT_SM = 600;
export const BREAKPOINT_MD = 960;

// Group Status
export const PARTNER_GROUP_STATUS_LICENSED = 'GroupLicensed';
export const PARTNER_GROUP_STATUS_UNLICENSED = 'GroupUnlicensed';
export const PARTNER_GROUP_STATUS_PENDING = 'GroupPending';
export const PARTNER_GROUP_STATUS_ACTIVATION_FAILED = 'GroupActivationFailed';
export const PARTNER_GROUP_STATUS_DEACTIVATION_FAILED =
  'GroupDeactivationFailed';

// User Status
export const PARTNER_USER_STATUS_LICENSED = 'Licensed';
export const PARTNER_USER_STATUS_UNLICENSED = 'Unlicensed';
export const PARTNER_USER_STATUS_PENDING = 'Pending';
export const PARTNER_USER_STATUS_ACTIVATION_FAILED = 'ActivationFailed';
export const PARTNER_USER_STATUS_DEACTIVATION_FAILED = 'DeactivationFailed';

// Display Status
export const DISPLAY_STATUS_LICENSED = 'Licensed';
export const DISPLAY_STATUS_PENDING = 'In Progress';
export const DISPLAY_STATUS_ACTIVATION_FAILED = 'Activation Failed';
export const DISPLAY_STATUS_DEACTIVATION_FAILED = 'Deactivation Failed';
export const DISPLAY_STATUS_NOT_LICENSED = 'Not Licensed';
