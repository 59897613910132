// Logout component
import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

function Logout() {
  const navigate = useNavigate();

  useEffect(() => {
    fetch('/auth/logout')
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(() => {
        // clear any user details from app state or local storage
        localStorage.clear();
        navigate('/');
      });
  }, [navigate]);

  return null; // This component doesn't render anything
}

export default Logout;
