import {useEffect} from 'react';
import {useDispatch} from 'react-redux';

import {fetchTenantStatus} from '../redux/actions/tenantActions';

const useTenant = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTenantStatus());
  }, [dispatch]);
};

export default useTenant;
