import {Navigate, useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';

export default function RequireAuth({children}) {
  const {
    authStatus: {isAuthenticated},
    isInitialized,
    isLoading,
  } = useSelector((state) => state.authStatus);
  const location = useLocation();

  if (isInitialized && !isLoading && isAuthenticated !== true) {
    return <Navigate to="/" state={{from: location}} replace />;
  }

  return children;
}

RequireAuth.propTypes = {
  children: PropTypes.node.isRequired,
};
