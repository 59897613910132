import {tenantActions} from '../actions/tenantActions';

const initialState = {
  tenantStatus: {
    consentUser: null,
    consentStatus: false,
    provisionStatus: null,
    provisionError: null,
    privacyPolicy: {accepted: false, userPrincipalName: null, url: null},
    eula: {accepted: false, userPrincipalName: null, url: null},
    tenantId: null,
  },
  deploymentStatus: null,
  isInitialized: false,
  isLoading: false,
  error: null,
  tenantTransitioning: false,
};

// eslint-disable-next-line default-param-last
const tenantReducer = (state = initialState, action) => {
  switch (action.type) {
    case tenantActions.FETCH_TENANT_STATUS_START:
    case tenantActions.UPDATE_TENANT_STATUS_START:
    case tenantActions.FETCH_TENANT_DEPLOYMENT_START:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case tenantActions.ENABLE_TENANT_START:
    case tenantActions.DISABLE_TENANT_START:
      return {
        ...state,
        isLoading: false,
        error: null,
        tenantStatus: {...state.tenantStatus, provisionError: null},
        tenantTransitioning: true,
      };
    case tenantActions.FETCH_TENANT_DEPLOYMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        tenantStatus: action.payload.tenantStatus,
        deploymentStatus: action.payload.deploymentStatus,
      };
    case tenantActions.FETCH_TENANT_STATUS_SUCCESS:
    case tenantActions.UPDATE_TENANT_STATUS_SUCCESS:
      return {
        ...state,
        isInitialized: true,
        isLoading: false,
        tenantStatus: action.payload,
      };
    case tenantActions.ENABLE_TENANT_SUCCESS:
    case tenantActions.DISABLE_TENANT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        tenantTransitioning: false,
      };
    case tenantActions.FETCH_TENANT_DEPLOYMENT_FAILURE:
    case tenantActions.FETCH_TENANT_STATUS_FAILURE:
    case tenantActions.UPDATE_TENANT_STATUS_FAILURE:
    case tenantActions.ENABLE_TENANT_FAILURE:
    case tenantActions.DISABLE_TENANT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        tenantTransitioning: false,
      };
    case tenantActions.SET_TENANT_TRANSITIONING:
      return {
        ...state,
        tenantTransitioning: action.payload,
      };
    default:
      return state;
  }
};

export default tenantReducer;
