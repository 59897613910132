import {LOGOUT_LINK} from './constants';

export function delay(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export function isEmpty(obj = {}) {
  return (
    [Object, Array].includes((obj || {}).constructor) &&
    !Object.entries(obj || {}).length
  );
}

export function isUndefined(value) {
  return typeof value === 'undefined';
}

export const handleLogout = async () => {
  try {
    window.location.href = LOGOUT_LINK;
  } catch (error) {
    throw new Error('Failed to logout');
  }
};
